import Section from '@/Components/Layouts/Section.jsx';
import {useNotifications} from '@/Components/Providers/NotificationsProvider.jsx';
import BedroomsQtyFieldset from '@/Pages/Configurator/Components/BedroomsQtyFieldset.jsx';
import GarageFieldset from '@/Pages/Configurator/Components/GarageFieldset.jsx';
import KitchenFieldset from '@/Pages/Configurator/Components/KitchenFieldset.jsx';
import LandFieldset from '@/Pages/Configurator/Components/LandFieldset.jsx';
import DefaultBathroomsDetails from '@/Pages/Configurator/Components/Themed/DefaultBathroomsDetails.jsx';
import DefaultBedroomsDetails from '@/Pages/Configurator/Components/Themed/DefaultBedroomsDetails.jsx';
import DefaultIntro from '@/Pages/Configurator/Components/Themed/DefaultIntro.jsx';
import DefaultOutro from '@/Pages/Configurator/Components/Themed/DefaultOutro.jsx';
import YaKBathroomsDetails from '@/Pages/Configurator/Components/Themed/YaKBathroomsDetails.jsx';
import YaKBedroomsDetails from '@/Pages/Configurator/Components/Themed/YaKBedroomsDetails.jsx';
import YaKIntro from '@/Pages/Configurator/Components/Themed/YaKIntro.jsx';
import YaKKitchenDetails from '@/Pages/Configurator/Components/Themed/YaKKitchenDetails.jsx';
import YaKOutro from '@/Pages/Configurator/Components/Themed/YaKOutro.jsx';
import ModalBudgetDetails from '@/Pages/Configurator/Modals/ModalBudgetDetails.jsx';
import ModalProspectForm from '@/Pages/Configurator/Modals/ModalProspectForm.jsx';
import {getUserErrorMessage, handleFieldsErrors} from '@/Scripts/Common/errors.js';
import useReCaptcha from '@/Scripts/Common/useReCaptcha.js';
import useRenderCount from '@/Scripts/Common/useRenderCount.js';
import ImagesStyle from '@/Styles/images.js';
import {Head, useForm, usePage} from '@inertiajs/react';
import {Alert, Box} from '@mui/material';
import postcodes_lib from 'codes-postaux';
import React, {memo, useMemo, useRef, useState} from 'react';

const land_section_type_options = [
    {label: 'Choisir un terrain parmi nos terrains disponibles', value: 'choice'},
    {label: 'Saisir les informations de mon terrain', value: 'fields'},
];

const styles = {
    paint_roller_icon: (theme) => ({
        position: 'relative',
        display: 'inline-block',
        '&::after': {
            ...ImagesStyle.background_contain('/theme/ya-k/images/icons/rouleau-peinture.svg'),
            content: '""',
            zIndex: 999,
            position: 'absolute',
            bottom: 0,
            right: 0,
            transform: 'translate(-150%, 25%)',
            width: '64px',
            height: '62px',
            [theme.breakpoints.up('md')]: {
                width: '96px',
                height: '93px',
            },
        },
    }),
    pendant_lights_icon: (theme) => ({
        position: 'relative',
        display: 'inline-block',
        '&::after': {
            ...ImagesStyle.background_contain('/theme/ya-k/images/icons/suspension-luminaire.svg'),
            content: '""',
            zIndex: 999,
            position: 'absolute',
            bottom: 0,
            right: 0,
            transform: 'translate(-150%, 50%)',
            width: '64px',
            height: '94px',
            [theme.breakpoints.up('md')]: {
                width: '96px',
                height: '141px',
            },
        },
    }),
    hanger_icon: (theme) => ({
        position: 'relative',
        display: 'inline-block',
        '&::after': {
            ...ImagesStyle.background_contain('/theme/ya-k/images/icons/cintre.svg'),
            content: '""',
            zIndex: 999,
            position: 'absolute',
            bottom: 0,
            right: 0,
            transform: 'translate(-150%, 35%)',
            width: '61px',
            height: '67px',
            [theme.breakpoints.up('md')]: {
                width: '91px',
                height: '100px',
            },
        },
    }),
};

const findPricingOptionsTypeByName = (types, name) => {
    return types.find((t) => t.name.toLowerCase().includes(name.toLowerCase()));
};
const findCurrentOrDefaultOption = (options, type, value) => {
    return value !== ''
        ? options.find((o) => o.type.name === type.name && parseInt(o.value) === value)
        : options.find((o) => o.type.name === type.name && o.default);
};

const findImpactPriceByCategory = (category, prices, comparator) =>
    prices.reduce((acc, curr) => (comparator(curr.amount, acc.amount) ? curr : acc), prices[0]);

const Index = ({origin, construction_postcode, price, pricing_basis}) => {
    const {props: page_props} = usePage();
    const {url_brand} = page_props;
    const {showNotifications} = useNotifications();
    const {reCaptchaLoaded, generateReCaptchaToken} = useReCaptcha();

    const main_form = useRef(null);
    const budget_details_form = useRef(null);

    const pricing_group = pricing_basis.groups[0];
    const pricing_group_amount = !isNaN(pricing_group.amount) ? parseFloat(pricing_group.amount) : undefined;
    const form = useForm({
        origin_ref: origin && origin !== '' ? origin : '',
        price: price && price !== '' ? price : (pricing_group_amount ?? ''),
        construction_postcode: construction_postcode && construction_postcode !== '' ? construction_postcode : '',
        construction_location_id: '',

        garage: '',
        bedrooms_qty: '',
        kitchen: '',

        down_payment: 10000,
        interest_rate: 3,
        insurance_rate: 0.3,
        repayment_period: 300,

        civility: '',
        lastname: '',
        firstname: '',
        phone: '',
        email: '',
        postcode: '',
        city: '',
        profil: '',
    });
    const {data, setData, errors, setError, clearErrors, reset} = form;

    const land_is_preconfigured = construction_postcode && price;
    const [landSectionType, setLandSectionType] = useState(construction_postcode || price ? 'fields' : '');

    const [loading, setLoading] = useState(false);
    const [openingBudgetDetails, setOpeningBudgetDetails] = useState(false);
    const [promptingUserInfo, setPromptingUserInfo] = useState(false);

    const cities_list = useMemo(() => {
        let res = [];
        const postcode_list = postcodes_lib.find(data.construction_postcode);
        if (postcode_list.length > 0) {
            res = postcode_list.map((item) => item.nomCommune);
        }
        return res;
    }, [data.construction_postcode]);

    const pricing_basis_prices = pricing_basis.prices.map((p) => {
        let options = [];
        try {
            options = JSON.parse(p.config);
        } catch (error) {
            console.warn(error);
        }
        return {...p, options};
    });

    const choices_categories = {
        garage: 'Garage',
        kitchen: 'Cuisine',
        bedrooms_qty: 'Chambre',
    };
    const choices_categories_keys = Object.keys(choices_categories);

    const pricing_types_by_category = Object.fromEntries(
        choices_categories_keys.map((category) => [
            category,
            findPricingOptionsTypeByName(pricing_basis.options_types, choices_categories[category]),
        ]),
    );

    const current_pricing_option_by_category = useMemo(
        () =>
            Object.fromEntries(
                choices_categories_keys.map((category) => [
                    category,
                    findCurrentOrDefaultOption(
                        pricing_basis.options,
                        pricing_types_by_category[category],
                        data[category],
                    ),
                ]),
            ),
        [data.garage, data.bedrooms_qty, data.kitchen],
    );

    const prices_by_category_restricted_by_other_categories = Object.fromEntries(
        choices_categories_keys.map((category) => {
            const other_categories_ids = choices_categories_keys
                .filter((other_category) => other_category !== category)
                .map((other_category) => current_pricing_option_by_category[other_category]?.uniq_id);

            const filtered_prices = pricing_basis_prices.filter((price) =>
                other_categories_ids.every((option_id) =>
                    price.options.some((price_option) => price_option.uniq_id === option_id),
                ),
            );
            return [category, filtered_prices];
        }),
    );

    const pricing_options_by_category = Object.fromEntries(
        choices_categories_keys.map((category) => [
            category,
            pricing_basis.options.filter(
                (option) => option.type.uniq_id === pricing_types_by_category[category]?.uniq_id,
            ),
        ]),
    );
    const pricing_options_ids_by_category = Object.fromEntries(
        Object.entries(pricing_options_by_category).map(([category, options]) => [
            category,
            options.map((option) => option.uniq_id),
        ]),
    );

    const highest_option_impact_price_by_category = Object.fromEntries(
        Object.entries(prices_by_category_restricted_by_other_categories).map(([category, prices]) => [
            category,
            findImpactPriceByCategory(category, prices, (a, b) => a > b),
        ]),
    );
    const lowest_option_impact_price_by_category = Object.fromEntries(
        Object.entries(prices_by_category_restricted_by_other_categories).map(([category, prices]) => [
            category,
            findImpactPriceByCategory(category, prices, (a, b) => a < b),
        ]),
    );
    const highest_option_impact_by_category = Object.fromEntries(
        Object.entries(highest_option_impact_price_by_category).map(([category, price]) => [
            category,
            price?.options.find((option) => pricing_options_ids_by_category[category].includes(option.uniq_id))
                ?.amount ?? 0,
        ]),
    );

    const generateSelectOptions = (category) => {
        return pricing_options_by_category[category].map((option) => {
            const prices_restricted_by_other_categories = prices_by_category_restricted_by_other_categories[category];
            const highest_option_impact_price = highest_option_impact_price_by_category[category];
            const highest_option_impact = highest_option_impact_by_category[category];
            const lowest_option_impact_price = lowest_option_impact_price_by_category[category];

            const current_price = prices_restricted_by_other_categories.find((price) =>
                price.options.some((price_option) => price_option.uniq_id === option.uniq_id),
            );
            const current_price_impact =
                current_price?.options.find((price_option) => price_option.uniq_id === option.uniq_id)?.amount ?? 0;

            const is_highest_impact = current_price?.uniq_id === highest_option_impact_price?.uniq_id;
            const is_lowest_impact = current_price?.uniq_id === lowest_option_impact_price?.uniq_id;
            let addon = is_highest_impact
                ? `+${highest_option_impact} €`
                : is_lowest_impact
                  ? 'inclus'
                  : `+${current_price_impact} €`;
            if (category === 'garage') {
                addon = is_highest_impact ? 'inclus' : `-${highest_option_impact} €`;
            }

            return {
                label: option.name,
                value: option.value,
                addon,
                impact: current_price_impact,
            };
        });
    };

    const garage_select_options = generateSelectOptions('garage');
    const kitchen_select_options = generateSelectOptions('kitchen');
    const bedrooms_qty_select_options = generateSelectOptions('bedrooms_qty');

    const garage_selected = garage_select_options.find((so) => so.value === data.garage + '') ?? '';
    const kitchen_selected = kitchen_select_options.find((so) => so.value === data.kitchen + '') ?? '';
    const bedrooms_qty_selected = bedrooms_qty_select_options.find((so) => so.value === data.bedrooms_qty + '') ?? '';

    const pricing_basis_dep_numeros = pricing_basis.departments.map((prd) => prd.numero).flat();
    const pricing_basis_postcodes = pricing_basis.postcodes.map((prd) => prd.code).flat();

    const location_increase = useMemo(() => {
        const curr_postcode = data.construction_postcode;
        const dep_num = curr_postcode.slice(0, 2);
        let pricing_basis_dep;
        let pricing_basis_postcode;
        let res = 0;
        if (pricing_basis_dep_numeros.includes(dep_num)) {
            pricing_basis_dep = pricing_basis.departments.find((prd) => prd.numero === dep_num);
            res = pricing_basis_dep?.increase ?? 0;
        }
        if (pricing_basis_postcodes.includes(curr_postcode)) {
            pricing_basis_postcode = pricing_basis.postcodes.find((ppc) => ppc.code === curr_postcode);
            res = pricing_basis_postcode?.increase ?? 0;
        }
        if (!isNaN(res)) {
            res = parseFloat(res);
        }
        return res;
    }, [data.construction_postcode]);

    const budget_allowance = useMemo(() => {
        if (!data.price || isNaN(data.price)) {
            return '';
        }
        let res = parseFloat(data.price);
        if (garage_selected) {
            res = parseFloat(parseFloat(res + garage_selected.impact).toFixed(0));
        }
        if (kitchen_selected) {
            res = parseFloat(parseFloat(res + kitchen_selected.impact).toFixed(0));
        }
        if (bedrooms_qty_selected) {
            res = parseFloat(parseFloat(res + bedrooms_qty_selected.impact).toFixed(0));
        }
        if (location_increase) {
            res = parseFloat(parseFloat(res * location_increase).toFixed(0));
        }
        return res;
    }, [data.price, data.garage, data.bedrooms_qty, data.kitchen, location_increase]);

    const amount_max_monthly = useMemo(() => {
        if (
            !budget_allowance ||
            isNaN(budget_allowance) ||
            !data.interest_rate ||
            isNaN(data.interest_rate) ||
            !data.repayment_period ||
            isNaN(data.repayment_period) ||
            !data.insurance_rate ||
            isNaN(data.insurance_rate)
        ) {
            return '';
        }

        const real_budget = budget_allowance - data.down_payment;
        const monthly_rate = data.interest_rate / 100 / 12;
        const total_payments = data.repayment_period;

        const monthly_payment =
            (real_budget * monthly_rate * Math.pow(1 + monthly_rate, total_payments)) /
            (Math.pow(1 + monthly_rate, total_payments) - 1);
        const insurance_payment = (real_budget * data.insurance_rate) / 100 / 12;
        const total_monthly_payment = monthly_payment + insurance_payment;

        return parseFloat(total_monthly_payment.toFixed(0));
    }, [budget_allowance, data.down_payment, data.interest_rate, data.repayment_period, data.insurance_rate]);

    useRenderCount('Configurator', {data, pricing_basis});

    const isValidForm = (form) => {
        return !form || form.checkValidity();
    };

    const openBudgetDetails = () => {
        setOpeningBudgetDetails(true);
    };

    const closeBudgetDetails = () => {
        setOpeningBudgetDetails(false);
    };

    const openPromptUserInfo = (e) => {
        e.preventDefault();

        if (!isValidForm(main_form?.current)) {
            return;
        }
        if (!isValidForm(budget_details_form?.current)) {
            openBudgetDetails();
            setTimeout(() => {
                budget_details_form.current.reportValidity();
            }, 0);
            return;
        }
        setPromptingUserInfo(true);
    };

    const closePromptUserInfo = () => {
        setPromptingUserInfo(false);
    };

    const handleControlledChange = ({name, value}) => {
        clearErrors(name);
        setData(name, value);
    };

    const handleConstructionPostCodeChange = ({name, value}) => {
        clearErrors(name);
        setData((prev) => ({
            ...prev,
            [name]: value,
            construction_location_id: '',
        }));
    };

    const handleNumericOptionChange = (e) => {
        const {name, value} = e.target;
        clearErrors(name);
        setData(name, parseInt(value));
    };

    const handleLandSectionTypeChange = (e) => {
        const {value} = e.target;
        setLandSectionType(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (errors?.construction_postcode) {
            showNotifications("Le champ d'adresse de construction n'est pas renseigné.", {
                severity: 'error',
                autoHideDuration: 3000,
            });
            return;
        }
        data.url_brand = url_brand;
        data.budget_allowance = budget_allowance;
        data.amount_max_monthly = amount_max_monthly;

        /*if (!reCaptchaLoaded) {
            console.error('ReCAPTCHA pas encore chargé.');
            return;
        }*/

        (async () => {
            try {
                //const token = await generateReCaptchaToken('submit_form');
                const response = await axios({
                    url: route('configurator.store'),
                    method: 'POST',
                    data: {
                        //'g-recaptcha-response': token,
                        ...data,
                    },
                });
                const resp_data = response?.data ?? {};

                if (resp_data?.message) {
                    showNotifications(resp_data.message, {
                        severity: 'success',
                        autoHideDuration: 3000,
                    });
                }
                closePromptUserInfo();
                reset();
            } catch (error) {
                const error_message = getUserErrorMessage(error);
                showNotifications(error_message, {
                    severity: 'error',
                    autoHideDuration: 3000,
                });
                handleFieldsErrors(error, setError);
            } finally {
                setLoading(false);
            }
        })();
    };

    return (
        <>
            <Head title="Configurateur" />

            {page_props?.flash?.message && (
                <Alert severity="success" sx={{justifyContent: 'center'}}>
                    {page_props.flash.message}
                </Alert>
            )}
            <Box component="form" ref={main_form} onSubmit={openPromptUserInfo}>
                {url_brand === 'ya-k' && <YaKIntro />}
                <Section>
                    {url_brand !== 'ya-k' && <DefaultIntro />}
                    <LandFieldset
                        url_brand={url_brand}
                        data={data}
                        errors={errors}
                        is_preconfigured={land_is_preconfigured}
                        cities_list={cities_list}
                        landSectionType={landSectionType}
                        handleLandSectionTypeChange={handleLandSectionTypeChange}
                        land_section_type_options={land_section_type_options}
                        handleControlledChange={handleControlledChange}
                        handleConstructionPostCodeChange={handleConstructionPostCodeChange}
                    />
                </Section>
                <Section>
                    <GarageFieldset
                        url_brand={url_brand}
                        data={data}
                        errors={errors}
                        garage_select_options={garage_select_options}
                        handleNumericOptionChange={handleNumericOptionChange}
                    />
                </Section>
                <Box
                    sx={(theme) => ({
                        backgroundColor: theme.palette.background.light,
                        ...(url_brand === 'ya-k' ? styles.paint_roller_icon(theme) : {}),
                    })}
                >
                    <Section
                        sx={{
                            overflowX: 'auto',
                            overflowY: 'hidden',
                        }}
                    >
                        {url_brand === 'ya-k' ? <YaKBedroomsDetails /> : <DefaultBedroomsDetails />}
                    </Section>
                </Box>
                <Section>
                    <BedroomsQtyFieldset
                        url_brand={url_brand}
                        data={data}
                        errors={errors}
                        bedrooms_qty_select_options={bedrooms_qty_select_options}
                        handleNumericOptionChange={handleNumericOptionChange}
                    />
                </Section>
                {url_brand === 'ya-k' && (
                    <Box
                        sx={(theme) => ({
                            backgroundColor: theme.palette.background.light,
                            ...(url_brand === 'ya-k' ? styles.pendant_lights_icon(theme) : {}),
                        })}
                    >
                        <Section
                            sx={{
                                overflowX: 'auto',
                                overflowY: 'hidden',
                            }}
                        >
                            <YaKKitchenDetails />
                        </Section>
                    </Box>
                )}
                <Section>
                    <KitchenFieldset
                        url_brand={url_brand}
                        data={data}
                        errors={errors}
                        kitchen_select_options={kitchen_select_options}
                        handleNumericOptionChange={handleNumericOptionChange}
                    />
                </Section>

                <Box
                    sx={(theme) => ({
                        backgroundColor: theme.palette.background.light,
                        ...(url_brand === 'ya-k' ? styles.hanger_icon(theme) : {}),
                    })}
                >
                    <Section
                        sx={{
                            overflowX: 'auto',
                            overflowY: 'hidden',
                        }}
                    >
                        {url_brand === 'ya-k' ? <YaKBathroomsDetails /> : <DefaultBathroomsDetails />}
                    </Section>
                </Box>
                <Section>
                    {url_brand === 'ya-k' ? (
                        <YaKOutro
                            url_brand={url_brand}
                            amount_max_monthly={amount_max_monthly}
                            openBudgetDetails={openBudgetDetails}
                        />
                    ) : (
                        <DefaultOutro
                            url_brand={url_brand}
                            amount_max_monthly={amount_max_monthly}
                            openBudgetDetails={openBudgetDetails}
                        />
                    )}
                </Section>
            </Box>

            <ModalBudgetDetails
                open={openingBudgetDetails}
                data={data}
                errors={errors}
                budget_allowance={budget_allowance}
                amount_max_monthly={amount_max_monthly}
                onChange={(name, value) => handleControlledChange({name, value})}
                onClose={closeBudgetDetails}
            />
            <ModalProspectForm
                open={promptingUserInfo}
                data={data}
                loading={loading}
                errors={errors}
                onChange={(name, value) => handleControlledChange({name, value})}
                onConfirm={handleSubmit}
                onClose={closePromptUserInfo}
            />
        </>
    );
};

export default memo(Index);
