import {useEffect, useRef} from 'react';

/**
 * Indique le nombre de fois où un composant a été rerendu
 * https://www.js-craft.io/blog/react-count-the-number-of-renders-for-an-individual-component/
 * @returns {boolean}
 */
const useRenderCount = (name = 'Unknown', props = {}) => {
    const rendersNo = useRef(1);
    useEffect(() => {
        rendersNo.current++;
    });
    console.log('Rerenders (' + name + '): ' + rendersNo.current);
    if (props) {
        console.log(JSON.parse(JSON.stringify(props)));
    }
};

export default useRenderCount;
