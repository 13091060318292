export const default_error_messages = {
    400: 'La requête est invalide. Veuillez vérifier les données que vous avez envoyées.',
    401: "Vous n'êtes pas autorisé à accéder à cette ressource. Veuillez vous connecter.",
    403: "L'accès à cette ressource est interdit.",
    404: "La ressource demandée n'a pas été trouvée.",
    500: 'Une erreur interne du serveur est survenue. Veuillez réessayer plus tard.',
    502: 'Mauvaise passerelle. Le serveur est actuellement inaccessible.',
    503: 'Le service est temporairement indisponible. Veuillez réessayer plus tard.',
    504: "Délai d'attente de la passerelle. La réponse du serveur a été trop longue.",
};

export const getUserErrorMessage = (error) => {
    return error?.response?.data?.message ||
    default_error_messages[error?.response?.status] ||
    `Erreur ${error?.response?.status}: ${error?.message}` ||
    "Une erreur inattendue s'est produite."
}

export const handleFieldsErrors = (error, setErrors) => {
    const fields_errors = error?.response?.data?.errors;
    
    if (fields_errors && Object.keys(fields_errors).length > 0) {
        const result = Object.fromEntries(
            Object.entries(fields_errors).map(([field_name, field_errors]) => {
                return [field_name, field_errors[0]];
            }),
        );
        setErrors(result);
    }
};
